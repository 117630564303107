@import "./App.scss";

//GLOBALS
* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}
a{
  text-decoration: none;
  cursor: pointer;
}

p,
span {
  font-size: 2rem;
  font-family: $headline-secondary;
}
p{
  color: $primary;
}
a{
  cursor: pointer;
}
html {
  font-size: 10px;
  line-height: 1.25;

}
h1{
  font-family: $headline-primary;
  font-weight: 700;
  font-size: 5rem;
  color: $primary;
  // line-height: 60px;

}
h2{
  font-family: $headline-secondary;
  font-weight: 400;
  font-size: 5rem;
  color: $primary;
  // line-height: 110px;
}
h3 {
  font-family: $headline-secondary;
  font-weight: 700;
  font-size: 2rem;
  color: $primary;
}
 h4{
  font-family: $headline-secondary;
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.2;
  color: $primary;
}



// MOBILE FIRST

.wrapper {
  padding: 4rem;
    @include lg {
    padding: 15rem 15rem;
  }
}



  h1{
    @include md{
    font-size: 10rem;
    line-height: 130px;
  }
}
  h2{
    @include md{
      font-size: 10rem;
    }
    @include xl{
      font-weight: 600;
    }
  }
h4{
  @include md{
    font-size: 4rem;
  }
}