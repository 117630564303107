@import '../App.scss';
@import url('https://fonts.googleapis.com/css?family=Baloo+2:400,800&display=swap');

.about {
  display: flex;
  justify-content: center;
  align-items: center;

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0rem 3rem;
    @include md {
      padding: 10rem 20rem;
    }
  }

  &__textContainer {
    @include row;
    justify-content: center;
    align-items: center;
  }
  &__text {
    max-width: 80rem;
    text-transform: lowercase;
    padding: 5rem 5rem 5rem 0;
  }
  &__pdfContainer {
    padding: 1rem;
    @include md {
      padding: 5rem;
      // z-index: 1;
    }
  }
  &__pdfText {
    // z-index: -1;
    font-size: 1rem;
    margin: 2rem;
    padding: 2rem;
    border: 1px solid rgba(145, 145, 145, 0.6);
    border-radius: 50px;
    background: linear-gradient(
      83.65deg,
      #f0eff1 0%,
      rgba(234, 76, 137, 0.12) 101.16%
    );
    @include md {
      font-size: 2rem;
    }
  }

  &__imageContainer {
    border-radius: 50%;
    height: 25rem;
    width: 25rem;
    padding-top: 5rem;
    @include md {
      // padding-left: 15rem;
      height: 35rem;
      width: 35rem;
    }
  }
  &__image {
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }
  &__titleBox {
    @include row;
    justify-content: center;
    align-items: center;
    padding: 5rem;
    @include md {
      padding: 10rem 0;
    }
  }
  &__title {
    text-transform: uppercase;
    margin-bottom: 5rem;
    &--stroke {
      @include text-border;
      color: $secondary;
      @include md {
        padding-right: 10rem;
      }
    }
  }
  &__bubble {
    transform: translateY(0px);
    animation: float 5s ease-in-out infinite;
    mix-blend-mode: multiply;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 3px;
    font-size: 15px;
    background-color: #ffffff;
    border: 1px solid rgba(#919191, 0.6);
    padding: 50px;
    border-radius: 11px;
    position: relative;
    box-shadow: 20px 20px $background-primary;
    font-family: 'Baloo 2';
    cursor: pointer;
    max-width: 20rem;
  }
  &__bubble:after {
    transform: translateY(0px);
    content: '.';
    animation: float2 5s ease-in-out infinite;
    -webkit-text-fill-color: hsl(0, 0%, 100%);
    -webkit-text-stroke: 0.5px rgb(0, 0, 0);
    text-shadow: 22px 22px $background-primary;
    text-align: left;
    font-size: 55px;
    font-weight: bold;
    line-height: 30px;
    width: 55px;
    height: 11px;
    border-radius: 11px;
    background-color: #ffffff;
    border: 1px solid #919191;
    position: absolute;
    display: block;
    bottom: -30px;
    left: 0;
    z-index: -2;
    box-shadow: 22px 22px $background-primary;
  }

  @keyframes float {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  @keyframes float2 {
    0% {
      line-height: 30px;
      transform: translateY(0px);
    }
    55% {
      transform: translateY(-20px);
    }
    60% {
      transform: translateY(-20px);
      line-height: 10px;
    }
    100% {
      line-height: 30px;
      transform: translateY(0px);
    }
  }
}

// CONTACT FORM
.popUp__wrapper {
  width: 80vw;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(
    83.65deg,
    #f0eff1 0%,
    rgba(234, 76, 137, 0.12) 101.16%
  );
  border-radius: 11px;
  position: absolute;
  padding-bottom: 5rem;
  margin-bottom: -40rem;
  @include md {
    margin-bottom: 10rem;
  }
}
span.close {
  display: flex;
  transform: translateX(15rem);
  color: #1b0f17;
  padding-left: 3rem;
  font-size: 5rem;
  cursor: pointer;
}
.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__containers {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
  }
  &__button {
    background: linear-gradient(
      90deg,
      #ea4c89 0%,
      rgba(14, 118, 168, 0.61) 100%
    );
    border-radius: 50px;
    color: #1b0f17;
    font-weight: 700;
    cursor: pointer;
    box-shadow: 4px 4px 8px rgba(#bea4a4, 0.7), -4px -4px 8px rgba(#f0e3e3, 0.7);
  }
}

input,
textarea {
  padding: 2rem 1.5rem;
  border-radius: 11px;
  border: #bea4a4;
  box-shadow: 4px 4px 8px rgba(#bea4a4, 0.7), -4px -4px 8px rgba(#f0e3e3, 0.7);
  width: 20rem;
}

@include md {
  input,
  textarea {
    padding: 2rem 2rem;
    width: 30rem;
  }
}

.contact-form p {
  color: #1b0f17;
  font-weight: 700;
  text-transform: uppercase;
}
