
// FONTS

$headline-primary: 'Archivo', sans-serif;
$headline-secondary: 'Work Sans', sans-serif;

// COLORS
$primary: #1f1f1f;
$secondary: #fff;
$background-primary: #f0eff1;



@mixin text-border {
  -webkit-text-stroke:3px #1f1f1f;
}
@mixin row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

// MEDIA-QUERIES

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

$screen-xxl-min: 1400px;

$screen-xxxl-min: 1500px;

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// Extra EXTRA large devices
@mixin xxl {
  @media (min-width: #{$screen-xxl-min}) {
    @content;
  }
}

// Extra EXTRA large devices
@mixin xxxl {
  @media (min-width: #{$screen-xxxl-min}) {
    @content;
  }
}

