@import "../App.scss";

.buttons{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  position:sticky;
  top: 0;
  z-index: 100;
  @include md {
    justify-content: flex-end;
    // top: 0;
    padding-right: 2rem;
    z-index: 1;
  }
}
.frame{
  background-color: $secondary;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 50px; width: 250px;
  box-shadow:
  -7px -7px 20px 0px #fff9,
  -4px -4px 5px 0px #fff9,
  7px 7px 20px 0px #0002,
  4px 4px 5px 0px #0001,
  inset 0px 0px 0px 0px #fff9,
  inset 0px 0px 0px 0px #0001,
  inset 0px 0px 0px 0px #fff9, inset 0px 0px 0px 0px #0001;
  transition:box-shadow 0.6s cubic-bezier(.79,.21,.06,.81);
  border-radius: 10px;
  @include md{
    flex-direction: column;
    height: 300px; 
    width: 60px;
  }
}
.btn{
  height: 35px; width: 35px;
  border-radius: 3px;
  background:$background-primary;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow:
  -7px -7px 20px 0px #fff9,
  -4px -4px 5px 0px #fff9,
  7px 7px 20px 0px #0002,
  4px 4px 5px 0px #0001,
  inset 0px 0px 0px 0px #fff9,
  inset 0px 0px 0px 0px #0001,
  inset 0px 0px 0px 0px #fff9, inset 0px 0px 0px 0px #0001;
  transition:box-shadow 0.6s cubic-bezier(.79,.21,.06,.81);
  font-size: 16px;
  color: rgba(42, 52, 84, 1);
  text-decoration: none;
  cursor: pointer;
}

.btn:active{
  box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
              -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.5),
    inset 4px 4px 6px 0 rgba(116, 125, 136, .3);
}
