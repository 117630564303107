@import "../App.scss";

.banner {
  position: relative;
  padding: 0 0 5rem 0;
  background: linear-gradient(360deg, #FFFFFF 0%, rgba(229, 229, 229, 0.46) 39.62%, rgba(231, 171, 194, 0.369034) 70.63%, rgba(234, 76, 137, 0.22) 100%);
  @include md{
padding: 0 0 30rem 0;
  }
  .banner-row {
    overflow: hidden;
    display: flex;
    align-items: center;
    color: $primary;
    padding-top: 4rem;
    @include md {
      &:first-child {
        margin: 0 0 0 10rem;
      }
    }
    &.center {
      justify-content: center;
      text-align: center;
      .row-letter {
        left: 0;
      }
    }
    &.right {
      justify-content: flex-end;
    }
    .row-title,
    .row-letter {
      font-family: $headline-primary;
      text-transform: lowercase;
      font-size: 6rem;
      font-weight: 700;
      position: relative;
      letter-spacing: -0.2rem;
      display: inline-block;
      white-space: nowrap;
      @include sm {
        font-size: 11rem;
        letter-spacing: -0.4rem;
      }
      @include md {
        font-size: 11rem;
      }
      @include lg {
        font-size: 21rem;
      }
    }
    .row-col {
      width: 70%;
      display: flex;
      align-content: center;
      justify-content: center;
      @include md {
        width: 100%;
      }
      @media (max-width: 1240px) {
        &:last-child {
          display: none;
        }
      }
      .row-message {
        font-family: $headline-secondary;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 2.6rem;
        width: 320px;
      }
    }
    .separateWords{
      position: relative;
      display: inline-block;
      white-space: pre-wrap;
    }
    .scroll {
      height: 160px;
      width: 160px;
      border-radius: 100%;
      background: linear-gradient(83.65deg, #F0EFF1 0%, rgba(234, 76, 137, 0.12) 101.16%);
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      left: 5px;
      box-shadow: 4px 4px 8px rgba(#b8b8b8, .7),-4px -4px 8px rgba(#ececec, .7) ;
    
      @include lg{
       
      }
      @include xxl{
        left: 40px;
      }
      @include xxxl {
        left: 100px;
      }
      @media (max-width: 1240px) {
        display: none;
      }
      span {
        font-family: $headline-secondary;
        font-size: 18px;
        font-weight: 600;
        margin: 4px 0;
      }
    }
  }
}


